<script setup lang="ts">

interface MyComponentProps {
	link?: string;
    title?:string;
    disabled?: boolean;
    light?: boolean;
    bordered?: boolean;
}
const props = defineProps<MyComponentProps>();
const emits = defineEmits(["click"]);

const click = () => {
    if(props.disabled) return;
    emits('click')
}
</script>
<template>
    <NuxtLink v-if="link" class="roundedButton" :to="link" :title="title" :disabled="disabled" :class="[{disabled: disabled}, {light: light}, {bordered: bordered}]" @click="click()">
        <slot/>
    </NuxtLink>
    
    <button v-else class="roundedButton" @click="click()" :title="title" :disabled="disabled" :class="[{disabled: disabled}, {light: light}, {bordered: bordered}]">
        <slot/>
    </button>    
</template>
<style>
.roundedButton {
  border: none;
  background-color: var(--mainColor);
  padding: 7px 10px;
  border-radius: 0.4rem;
  text-decoration: none;
  transition: var(--transition-duration) all;
  width: fit-content;
  font-size: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
  color: white;
}
.roundedButton svg {
  --icon-size: 10px;
  min-width: var(--icon-size);
  min-height: var(--icon-size);
  width: var(--icon-size);
  height: var(--icon-size);
}
.roundedButton.disabled {
  background-color: var(--mainColor-50);
  pointer-events: none;
  cursor: default;
}
.roundedButton.light {
  background-color: white;
  color: initial;
  border: 1px var(--default-border-color) solid;
}
.roundedButton.bordered {
  border: 2px currentColor solid;
}
</style>